export const getClassNameLeftIcon = (size: string, leftGroup: boolean) => {
  let sizeClass = ''
  switch (size) {
    case 'small':
      sizeClass = 'w-[32px]'
      break

    case 'large':
      sizeClass = 'w-[42px]'
      break

    case 'large-xl':
      sizeClass = 'w-[48px]'
      break

    default:
      sizeClass = 'w-[38px]'
      break
  }

  return `absolute top-0 bottom-0 ${
    leftGroup ? 'left-[54px]' : 'left-0'
  } inline-flex items-center justify-center ${sizeClass}`
}

export const getClassNameLeftGroup = () => {
  return 'absolute top-[1px] bottom-[1px] left-[1px] w-[36px] text-[14px] text-[#6B7280] flex items-center justify-center'
}

export const getClassNameRightIcon = (size: string, rightGroup: boolean) => {
  let sizeClass = ''
  switch (size) {
    case 'small':
      sizeClass = 'w-[32px]'
      break

    case 'large':
      sizeClass = 'w-[42px]'
      break

    case 'large-xl':
      sizeClass = 'w-[48px]'
      break

    default:
      sizeClass = 'w-[38px]'
      break
  }

  return `absolute top-0 bottom-0 ${
    rightGroup ? 'right-[54px]' : 'right-0'
  } inline-flex items-center justify-center ${sizeClass}`
}

export const getClassNameRightGroup = () => {
  return 'absolute top-[1px] bottom-[1px] right-[1px] w-[54px] text-[14px] text-[#6B7280] flex items-center justify-center'
}

export const getClassNameInput = ({
  size,
  leftInput,
  leftGroup,
  rightInput,
  rightGroup,
  disabled,
  status
}: {
  size?: string
  leftInput?: boolean
  leftGroup?: boolean
  rightInput?: boolean
  rightGroup?: boolean
  disabled: boolean
  status: string
}) => {
  const statusError = status === 'error'
  let className = 'inline-flex items-center justify-center w-full'
  let textClass = `${
    statusError
      ? 'placeholder:text-red-900'
      : disabled
      ? 'placeholder:text-gray-500'
      : 'placeholder:text-gray-600'
  } ${
    statusError ? 'text-red-900' : disabled ? 'text-gray-500' : 'text-gray-1000'
  }`
  let shapeClass = 'rounded-[6px]'
  let disableClass = disabled ? 'pointer-events-none' : ''
  let colorClass = `outline-0 ${
    statusError
      ? 'bg-white border border-soilid border-red-400'
      : disabled
      ? 'bg-[#F6F8FA] border border-soilid border-[#D1D5DB]'
      : 'bg-white bg-white border border-solid border-[#D1D5DB] hover:border-[#D1D5DB] focus:border-primary-600'
  }`
  let sizeClass = ''
  switch (size) {
    case 'small':
      sizeClass = `${
        leftInput
          ? 'pl-[32px]'
          : leftGroup
          ? statusError
            ? 'pl-[96px]'
            : 'pl-[64px]'
          : 'pl-3'
      } ${
        rightInput
          ? 'pr-[32px]'
          : rightGroup
          ? statusError
            ? 'pr-[96px]'
            : 'pl-[64px]'
          : 'pr-3'
      } h-[32px]`
      textClass += ' text-[14px]'
      break

    case 'large':
      sizeClass = `${
        leftInput
          ? 'pl-[42px]'
          : leftGroup
          ? statusError
            ? 'pl-[106px]'
            : 'pl-[64px]'
          : 'pl-3'
      } ${
        rightInput
          ? 'pr-[42px]'
          : rightGroup
          ? statusError
            ? 'pr-[106px]'
            : 'pl-[64px]'
          : 'pr-3'
      } h-[42px]`
      textClass += ' text-[16px]'
      break

    case 'large-xl':
      sizeClass = `${
        leftInput
          ? 'pl-[48px]'
          : leftGroup
          ? statusError
            ? 'pl-[112px]'
            : 'pl-[38px]'
          : 'pl-3'
      } ${
        rightInput
          ? 'pr-[48px]'
          : rightGroup
          ? statusError
            ? 'pr-[112px]'
            : 'pl-[64px]'
          : 'pr-3'
      } h-[48px]`
      textClass += ' text-[16px]'
      break

    default:
      sizeClass = `${
        leftInput
          ? 'pl-[38px]'
          : leftGroup
          ? statusError
            ? 'pl-[102px]'
            : 'pl-[64px]'
          : 'pl-3'
      } ${
        rightInput
          ? 'pr-[38px]'
          : rightGroup
          ? statusError
            ? 'pr-[102px]'
            : 'pr-[64px]'
          : 'pr-3'
      } h-[38px]`
      textClass += ' text-[14px]'
      break
  }

  return `${className} ${sizeClass} ${textClass} ${colorClass} ${shapeClass} ${disableClass}`
}
