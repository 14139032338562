export function removeEmptyProp(params: any): object {
  return Object.keys(params).reduce(
    (final, key) => ({
      ...final,
      ...(params[key] !== undefined ? { [key]: params[key] } : {})
    }),
    {}
  )
}

export const TIME_FORMAT = 'DD/MM/YYYY'

export function isHTML(content?: string): boolean {
  if (content && content.match('</[^>]+>')) {
    return true
  }
  return false
}

export function employmentText(type: number, language: string) {
  const temp = {
    0: { name: 'Full time', name_vn: 'Toàn thời gian' },
    1: { name: 'Part time', name_vn: 'Bán thời gian' },
    2: { name: 'Internship', name_vn: 'Thực tập sinh' },
    5: { name: 'Freelancer', name_vn: 'Làm việc tự do' },
    9: { name: 'Work remote', name_vn: 'Làm việc từ xa' },
    6: { name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
  }[type]
  return temp ? (language === 'en' ? temp.name : temp?.name_vn || '') : ''
}
