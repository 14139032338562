import { FC } from 'react'
const LinkedinIcon: FC<{ color?: string; size?: number }> = ({
  color = '#fff',
  size = 15
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none">
      <path
        d="M14.998 14.85V9.715c0-2.512-.54-4.447-3.477-4.447a3.048 3.048 0 0 0-2.746 1.51h-.04V5.502H5.952v9.347h2.9v-4.629c0-1.219.231-2.4 1.74-2.4 1.487 0 1.51 1.391 1.51 2.475v4.551l2.895.003zM1.234 5.503h2.9v9.347h-2.9V5.503zM2.681.85a1.689 1.689 0 1 0 1.681 1.681 1.682 1.682 0 0 0-1.68-1.68z"
        fill={color}
      />
    </svg>
  )
}

export default LinkedinIcon
