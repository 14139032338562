import { FC } from 'react'
const EyesIcon: FC<{ color?: string; size?: number }> = ({
  color = '#6B7280',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={(size * 17) / 16}
      height={size}
      viewBox="0 0 17 16"
      fill="none">
      <g clipPath="url(#f7sxnda38a)" fill={color}>
        <path d="M8.5 9.6a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.866 8a8.004 8.004 0 0 1 15.268 0A8.004 8.004 0 0 1 .866 8zM11.7 8a3.2 3.2 0 1 1-6.4 0 3.2 3.2 0 0 1 6.4 0z"
        />
      </g>
      <defs>
        <clipPath id="f7sxnda38a">
          <path
            fill="transparent"
            transform="translate(.5)"
            d="M0 0h16v16H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EyesIcon
