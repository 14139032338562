import { FC } from 'react'
const FacebookIcon: FC<{ color?: string; size?: number }> = ({
  color = '#fff',
  size = 15
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={(size * 8) / 15}
      height={size}
      viewBox="0 0 8 15"
      fill="none">
      <path
        d="M7.471.85H5.657a3.188 3.188 0 0 0-3.358 3.445v1.589H.473a.285.285 0 0 0-.285.286v2.3a.285.285 0 0 0 .285.285h1.825v5.808a.285.285 0 0 0 .284.285h2.383a.285.285 0 0 0 .285-.285V8.757h2.133a.285.285 0 0 0 .285-.285V6.17a.286.286 0 0 0-.285-.286H5.25V4.537c0-.647.154-.976 1-.976h1.223a.285.285 0 0 0 .285-.285V1.138A.286.286 0 0 0 7.471.85z"
        fill={color}
      />
    </svg>
  )
}

export default FacebookIcon
