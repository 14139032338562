/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'
import { Button } from '../../../components/Button'
import BriefCaseIcon from '../../../components/ui/icon/BriefCaseIcon'
import DollarIcon from '../../../components/ui/icon/DollarIcon'
import EyesIcon from '../../../components/ui/icon/EyesIcon'
import MarkIcon from '../../../components/ui/icon/MarkIcon'
import PaperAirPlane from '../../../components/ui/icon/PaperAirPlane'
import StartIcon from '../../../components/ui/icon/StartIcon'
import { JobIndexType } from '../job.types'
import { Link as LinkProp } from '../../../link'
import Link from 'next/link'
import LogoCompanyView from './LogoCompany'

export const listJobTypeFixture = [
  {
    id: '0',
    name: 'Full time',
    name_vn: 'Toàn thời gian'
  },
  {
    id: '1',
    name: 'Part time',
    name_vn: 'Bán thời gian'
  },
  {
    id: '2',
    name: 'Intern',
    name_vn: 'Thực tập'
  },
  { id: '5', name: 'Freelancer', name_vn: 'Freelancer' },
  {
    id: '9',
    name: 'Remote work',
    name_vn: 'Làm việc từ xa'
  },
  { id: '6', name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
]
const JobItem: FC<{ job: JobIndexType }> = ({ job }) => {
  return (
    <Link href={LinkProp.LINK_URL_JOB_DETAILS(job.slug, job.id)}>
      <a
        target="_blank"
        className="block group border border-[#e5e7eb] rounded p-4 hover:shadow-[0_1px_5px_0_rgba(0, 0, 0, 0.1)] relative">
        <style jsx global>{`
          .line-camp-1 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        `}</style>
        <div className="flex justify-between items-start mb-2">
          <div className="flex flex-1 items-start">
            <div className="mr-3 rounded-md border border-[#ebedf0] overflow-hidden">
              <LogoCompanyView
                title={job?.company?.data.attributes.name}
                alt={job?.company?.data.attributes.name}
                src={job?.company?.data.attributes.logo_url}
                size={40}
              />
            </div>
            <div>
              <div className="text-sm text-[#111827] font-medium line-camp-1">
                {job?.company?.data.attributes.name}
              </div>
              <div className="flex items-center">
                <MarkIcon color={'#6b7280'} size={14} />
                <span className="ml-1 text-[12px] leading-5 text-[#6b7280]">
                  {
                    job?.company?.data.attributes.location?.data?.attributes
                      ?.state
                  }
                </span>
              </div>
            </div>
          </div>
          {job?.hot_job && (
            <div className="uppercase text-white text-xs font-bold bg-[#ff4d4f] rounded-[20px] flex items-center py-0.5 px-1.5 ml-3">
              <span className="mr-0.5">HOT</span>
              <StartIcon />
            </div>
          )}
        </div>
        <h3 className="text-4 leading-6 font-bold text-[#141921] mb-2">
          {job?.title}
        </h3>
        <div className="flex items-center flex-wrap">
          {job?.salary_type === 'negotiable' ? (
            <div className="flex items-center mr-6">
              <DollarIcon />
              <span className="text-sm text-[#6b7280] ml-1.5">Negotiable</span>
            </div>
          ) : (
            <div className="flex items-center mr-6">
              <DollarIcon />
              <span className="text-sm text-[#6b7280] ml-1.5">
                Sign in to view salary
              </span>
            </div>
          )}
          <div className="flex items-center">
            <BriefCaseIcon color={'#9ca3af'} size={16} />
            <span className="text-sm text-[#6b7280] ml-1.5">
              {
                listJobTypeFixture.filter(
                  (job_employment_type) =>
                    job_employment_type?.id === String(job?.employment_type)
                )[0]?.name
              }
            </span>
          </div>
        </div>
        <div className="flex flex-wrap">
          {job?.skills_lst && job?.skills_lst[0] && (
            <div
              key={`skill-${job?.skills_lst[0]?.id}`}
              className="bg-[#f3f4f6] py-[5px] px-2 rounded-md mr-2 mt-2 text-sm text-[#374151]">
              {job?.skills_lst[0].name}
            </div>
          )}
          {job?.skills_lst && job?.skills_lst[1] && (
            <div
              key={`skill-${job?.skills_lst[1]?.id}`}
              className="bg-[#f3f4f6] py-[5px] px-2 rounded-md mr-2 mt-2 text-sm text-[#374151]">
              {job?.skills_lst[1].name}
            </div>
          )}
          {job?.skills_lst && job?.skills_lst?.length > 2 && (
            <div
              key={`skill-${job?.skills_lst[2]?.id}`}
              className="bg-[#f3f4f6] py-[5px] px-2 rounded-md mr-2 mt-2 text-sm text-[#374151]">
              +{job?.skills_lst?.length - 2}
            </div>
          )}
        </div>
        <div
          className="hidden group-hover:block hover:block w-full
        absolute bottom-0 left-0 p-4 bg-gradient-to-t from-[#ffffff] via-[rgba(255, 255, 255, 0.78)] to-[rgba(255, 255, 255, 0)]">
          <div className="grid grid-cols-2 gap-4">
            <Button
              onClick={() =>
                window.open(
                  LinkProp.LINK_URL_JOB_DETAILS(job.slug, job.id),
                  '_blank'
                )
              }
              type="secondary">
              <div className="flex items-center">
                <EyesIcon />
                <span className="ml-2">View job</span>
              </div>
            </Button>

            <Button
              onClick={() =>
                window.open(
                  `${LinkProp.CANDIDATE_APPLY_JOB(
                    job?.slug,
                    job?.id
                  )}?openModalApply=true`,
                  '_blank'
                )
              }
              type="primary">
              <div className="flex items-center">
                <span className="-rotate-45">
                  <PaperAirPlane />
                </span>
                <span className="ml-2">Apply job</span>
              </div>
            </Button>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default JobItem
