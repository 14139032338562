import Image from 'next/image'
import { FC } from 'react'
import FreeCWhiteLogo from '../../../public/img/logo-freec-white.png'
import FacebookIcon from '../ui/icon/FacebookIcon'
import LinkedinIcon from '../ui/icon/LinkedinIcon'
import MailIcon from '../ui/icon/MailIcon'
import { Link } from '../../link'

const Footer: FC = () => {
  return (
    <div className="bg-[#1f2937] p-8 text-center sm:text-left sm:flex sm:justify-between sm:items-center">
      <a href={Link.JOB_LIST} className="mb-4 sm:mb-0">
        <Image src={FreeCWhiteLogo} width={71} height={48} alt="freec logo" />
      </a>
      <div className="text-[#9ca3af] text-base mb-4 sm:mb-0">
        All rights reserved © freeC 2022
      </div>
      <div className="flex items-center justify-center">
        <a className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-[#fff] bg-opacity-10 mr-4">
          <FacebookIcon />
        </a>
        <a className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-[#fff] bg-opacity-10 mr-4">
          <LinkedinIcon />
        </a>
        <a className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-[#fff] bg-opacity-10">
          <MailIcon />
        </a>
      </div>
    </div>
  )
}

export default Footer
