import { ReactNode } from 'react'
import {
  getClassNameTooltip,
  getClassNameTooltipContent,
  getClassNameTooltipContentArrow
} from './index.utils'
import * as TooltipWrapper from '@radix-ui/react-tooltip'

interface TooltipProps {
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'right'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft'
    | 'left'
  children?: ReactNode
  content?: ReactNode
}

export const Tooltip = ({
  placement = 'topLeft',
  children,
  content
}: TooltipProps) => {
  const tooltipClassName = getClassNameTooltip(placement)
  const tooltipContentClassName = getClassNameTooltipContent(placement)
  const tooltipContentArrowClassName =
    getClassNameTooltipContentArrow(placement)

  return (
    <TooltipWrapper.Provider>
      <TooltipWrapper.Root>
        <TooltipWrapper.Trigger>{children}</TooltipWrapper.Trigger>

        <TooltipWrapper.Portal className={tooltipClassName}>
          <TooltipWrapper.Content className={tooltipContentClassName}>
            {content}
            <TooltipWrapper.Arrow className={tooltipContentArrowClassName} />
          </TooltipWrapper.Content>
        </TooltipWrapper.Portal>
      </TooltipWrapper.Root>
    </TooltipWrapper.Provider>
  )
}
