import { FC } from 'react'
const DollarIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9CA3AF',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M6.746 5.934c.124-.082.277-.156.454-.213v1.358a1.844 1.844 0 0 1-.454-.213C6.455 6.672 6.4 6.49 6.4 6.4c0-.091.055-.272.346-.466zM8.8 10.28V8.92c.176.058.33.132.453.214.291.194.347.375.347.466 0 .091-.056.272-.347.466a1.842 1.842 0 0 1-.453.213z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8zM8.8 4a.8.8 0 1 0-1.6 0v.074a3.628 3.628 0 0 0-1.341.529c-.578.385-1.06 1.004-1.06 1.797 0 .793.482 1.412 1.06 1.797.383.256.843.436 1.34.53v1.552c-.312-.101-.544-.254-.674-.403a.8.8 0 1 0-1.209 1.048c.45.519 1.131.86 1.884 1.002V12a.8.8 0 1 0 1.6 0v-.074a3.628 3.628 0 0 0 1.34-.529c.578-.385 1.06-1.005 1.06-1.797 0-.793-.482-1.412-1.06-1.797a3.628 3.628 0 0 0-1.34-.53V5.722c.312.101.544.253.674.403a.8.8 0 1 0 1.209-1.048c-.45-.519-1.131-.86-1.883-1.002V4z"
        fill={color}
      />
    </svg>
  )
}

export default DollarIcon
