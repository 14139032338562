import { ReactNode } from 'react'

export const getClassNameIcon = (
  iconPlacement: string,
  size: string,
  icon: ReactNode
) => {
  const placementRight = iconPlacement === 'right'
  let className = ''
  switch (size) {
    case 'thin':
      className = placementRight ? 'mr-[6px]' : 'ml-[6px]'
      break

    case 'small':
      className = placementRight ? 'mr-[8px]' : 'ml-[8px]'
      break

    case 'large':
      className = placementRight ? 'mr-[11px]' : 'ml-[11px]'
      break

    case 'large-xl':
      className = placementRight ? 'mr-[11px]' : 'ml-[11px]'
      break

    default:
      className = placementRight ? 'mr-[10px]' : 'ml-[10px]'
      break
  }

  return {
    iconClassName: placementRight ? 'order-last' : '',
    iconPlacementClassName: icon ? className : ''
  }
}

export const getClassNameButton = ({
  size,
  type,
  ghost,
  shape,
  disabled,
  hasChildren,
  buttonGroup
}: {
  size: string
  type: string
  ghost: boolean
  shape: string
  disabled: boolean
  hasChildren: ReactNode
  buttonGroup: boolean
}) => {
  const shapeCircle = shape === 'circle'
  let className = `inline-flex items-center justify-center${
    buttonGroup ? ' relative' : ''
  }`
  let colorClass = ''
  let textClass = ''

  let shapeClass = 'rounded-[6px]'
  if (shapeCircle) {
    shapeClass = 'rounded-full'
  }

  let disableClass = ''
  if (disabled) {
    disableClass = 'pointer-events-none'
  }

  let sizeClass = ''
  switch (size) {
    case 'thin':
      sizeClass =
        shapeCircle || !hasChildren
          ? 'w-[24px] h-[24px]'
          : buttonGroup
          ? 'pl-[8px] pr-[32px] h-[24px]'
          : 'px-2 h-[24px]'
      textClass = 'text-[12px]'
      break

    case 'small':
      sizeClass =
        shapeCircle || !hasChildren
          ? 'w-[32px] h-[32px]'
          : buttonGroup
          ? 'pl-[12px] pr-[44px] h-[32px]'
          : 'px-3 h-[32px]'
      textClass = 'text-[14px]'
      break

    case 'large':
      sizeClass =
        shapeCircle || !hasChildren
          ? 'w-[42px] h-[42px]'
          : buttonGroup
          ? 'pl-[18px] pr-[60px] h-[42px]'
          : 'pl-[18px] pr-[18px] h-[42px]'
      textClass = 'text-[16px]'
      break

    case 'large-xl':
      sizeClass =
        shapeCircle || !hasChildren
          ? 'w-[48px] h-[48px]'
          : buttonGroup
          ? 'pl-[24px] pr-[72px] h-[48px]'
          : 'px-6 h-[48px]'
      textClass = 'text-[16px]'
      break

    default:
      sizeClass =
        shapeCircle || !hasChildren
          ? 'w-[38px] h-[38px]'
          : buttonGroup
          ? 'pl-[18px] pr-[56px] h-[38px]'
          : 'pl-[18px] pr-[18px] h-[38px]'
      textClass = 'text-[14px]'
      break
  }

  if (type === 'primary') {
    if (ghost) {
      colorClass = `${
        disableClass
          ? 'bg-white'
          : 'bg-white hover:bg-gray-200 focus:bg-gray-300'
      } text-primary-600`
    } else {
      colorClass = `${
        disableClass
          ? 'bg-[#8bc7ff]'
          : 'bg-primary-600 hover:bg-primary-700 focus:bg-primary-800'
      } text-white`
    }
  }
  if (type === 'tertiary') {
    colorClass = `${
      disableClass
        ? 'bg-[#f5f7f9]'
        : 'bg-gray-200 hover:bg-gray-300 focus:bg-gray-400'
    } text-gray-800`
  }
  if (type === 'secondary') {
    if (ghost) {
      colorClass = `${
        disableClass
          ? 'bg-white'
          : 'bg-white hover:bg-gray-200 focus:bg-gray-300'
      } text-gray-800`
    } else {
      colorClass = `${
        disableClass
          ? 'bg-white border-[#eeeff1]'
          : 'bg-white hover:bg-gray-200 focus:bg-gray-200 focus:border-primary-600 border-[#D1D5DB]'
      } border border-solid text-gray-800`
    }
  }
  if (type === 'danger') {
    if (ghost) {
      colorClass = `${
        disableClass
          ? 'bg-white'
          : 'bg-white hover:bg-gray-200 focus:bg-gray-300'
      } text-red-600`
    } else {
      colorClass = `${
        disableClass
          ? 'bg-[#ffa6a7]'
          : 'bg-red-600 hover:bg-red-700 focus:bg-red-800'
      } text-white`
    }
  }
  if (type === 'outline-danger') {
    colorClass = `${
      disableClass
        ? 'bg-white border-[#ffd3d3]'
        : 'bg-white hover:bg-red-100 focus:bg-red-100 focus:border-red-800 border-red-600'
    } border border-solid text-red-600`
  }
  if (type === 'dashed') {
    colorClass = `${
      disableClass
        ? 'bg-white border-[#eff1f3]'
        : 'bg-white hover:bg-gray-200 focus:bg-gray-200 focus:border-primary-600 border-gray-400'
    } border border-dashed text-gray-600`
  }

  return `${className} ${sizeClass} ${textClass} ${colorClass} ${shapeClass} ${disableClass}`
}
