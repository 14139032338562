export const getClassNameTooltip = (placement: string) => {
  return placement
}

export const getClassNameTooltipContent = (placement: string) => {
  return placement
}

export const getClassNameTooltipContentArrow = (placement: string) => {
  return placement
}
