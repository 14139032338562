import Head from 'next/head'
import { FC } from 'react'
import ENV from '../../env'

const THUMBNAIL_COMPANY_LP_HH_1 = '/img/company_lp_hh_1.jpg'
const THUMBNAIL_COMPANY_LP_HH_2 = '/img/company_lp_hh_2.jpg'
const removeEndSplash = (link: string) => link?.replace(/\/$/g, '')

const randomThumbnail = () => {
  const randNum = Math.floor(Math.random() * 2)
  return randNum === 0 ? THUMBNAIL_COMPANY_LP_HH_1 : THUMBNAIL_COMPANY_LP_HH_2
}
const PublicPageMeta: FC<{
  title: string
  description: string
  keywords: string
  image?: string
  url?: string
  isAllowNoIndex?: boolean
  alternateLink: { url: string; lang: string }
}> = ({
  title,
  description,
  keywords,
  image,
  isAllowNoIndex,
  alternateLink,
  url
}) => {
  const imageThumbnail = randomThumbnail()
  return (
    <Head>
      <title>{title}</title>

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}

      {keywords && <meta itemProp="keywords" content={keywords} />}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta
        itemProp="image"
        content={image || `${ENV.PUBLIC_REMOTE_JOB_URL}${imageThumbnail}`}
      />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={image || `${ENV.PUBLIC_REMOTE_JOB_URL}${imageThumbnail}`}
      />
      {url && <meta property="og:url" content={removeEndSplash(url)} />}
      {isAllowNoIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta property="robots" content="noodp,index,follow" />
      )}
      <meta property="og:type" content="website" />
      <meta
        property="fb:app_id"
        content={`${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`}
      />

      <meta name="twitter:card" content={title} />
      <meta name="twitter:site" content="freec.asia" />
      <meta
        name="twitter:image"
        content={
          image || `${process.env.NEXT_PUBLIC_APP_DOMAIN}${imageThumbnail}`
        }
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      {alternateLink ? (
        <link
          rel="alternate"
          href={`${alternateLink?.url}`}
          hrefLang={alternateLink?.lang}
        />
      ) : (
        <link
          key="en"
          rel="alternate"
          href={`${ENV.PUBLIC_REMOTE_JOB_URL}${url}`}
          hrefLang={'en'}
        />
      )}
    </Head>
  )
}

export default PublicPageMeta
