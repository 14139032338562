import { FC } from 'react'
const MarkIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9CA3AF',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04 3.24a5.6 5.6 0 1 1 7.92 7.92L8 15.12l-3.96-3.96a5.6 5.6 0 0 1 0-7.92zM8 8.8a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2z"
        fill={color}
      />
    </svg>
  )
}

export default MarkIcon
