import { Tooltip } from '../../../../core/components/Tooltip'
import Image from 'next/image'
import COMPANY_CHECKED from './assets/checked.svg'
import { useTranslation } from 'react-i18next'
const TooltipVerified = () => {
  const { t } = useTranslation()
  return (
    <Tooltip content={t('content:company_verify')}>
      <Image
        src={COMPANY_CHECKED}
        alt="company-verify"
        width={16}
        height={16}
      />
    </Tooltip>
  )
}

export default TooltipVerified
