import { ReactNode } from 'react'
import {
  getClassNameButton,
  getClassNameIcon
} from '../../core/components/Button/index.utils'

export interface ButtonCommonProps {
  label?: string
  size?: 'thin' | 'small' | 'default' | 'large' | 'large-xl'
  type?:
    | 'primary'
    | 'tertiary'
    | 'secondary'
    | 'danger'
    | 'outline-danger'
    | 'dashed'
  callback?: () => void
}

export interface ButtonProps {
  size?: 'thin' | 'small' | 'default' | 'large' | 'large-xl'
  type?:
    | 'primary'
    | 'tertiary'
    | 'secondary'
    | 'danger'
    | 'outline-danger'
    | 'dashed'
  ghost?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  shape?: 'rounded' | 'circle'
  loading?: boolean
  title?: string
  children?: ReactNode
  icon?: ReactNode
  iconPlacement?: 'left' | 'right'
  htmlType?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  className?: string
}

export const Button = ({
  size = 'default',
  type = 'primary',
  ghost = false,
  onClick = undefined,
  shape = 'rounded',
  loading = false,
  title,
  children,
  icon,
  iconPlacement = 'left',
  htmlType = 'submit',
  disabled = false,
  className
}: ButtonProps) => {
  const { iconClassName, iconPlacementClassName } = getClassNameIcon(
    iconPlacement,
    size,
    icon
  )
  const classNameButton = getClassNameButton({
    size,
    type,
    ghost,
    shape,
    disabled,
    hasChildren: title || children,
    buttonGroup: false
  })
  return (
    <button
      autoFocus={false}
      className={`${className} ${classNameButton}`}
      type={htmlType}
      onClick={(event) => {
        if (onClick) {
          onClick(event)
        }
      }}>
      {loading ? (
        <span className={iconClassName}>Loading...</span>
      ) : icon ? (
        <span className={iconClassName}>{icon}</span>
      ) : (
        ''
      )}
      {title || children ? (
        <span className={iconPlacementClassName}>{title || children}</span>
      ) : null}
    </button>
  )
}
