import { FC } from 'react'
const PaperAirPlane: FC<{ color?: string; size?: number; className?: string }> =
  ({ color = '#fff', size = 16, className }) => {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none">
        <path
          d="M13.958 8.716a.8.8 0 0 0 0-1.432l-11.2-5.6a.8.8 0 0 0-1.127.936l1.143 4a.8.8 0 0 0 .77.58H7.2a.8.8 0 1 1 0 1.6H3.543a.8.8 0 0 0-.769.58l-1.143 4a.8.8 0 0 0 1.127.935l11.2-5.6z"
          fill={color}
        />
      </svg>
    )
  }

export default PaperAirPlane
