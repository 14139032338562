import { FC } from 'react'
const BriefCaseIcon: FC<{ color?: string; size?: number }> = ({
  color = '#36BA7E',
  size = 24
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2 7.2V6a3.6 3.6 0 0 1 3.6-3.6h2.4A3.6 3.6 0 0 1 16.8 6v1.2h2.4a2.4 2.4 0 0 1 2.4 2.4v4.285A27.54 27.54 0 0 1 12 15.6a27.54 27.54 0 0 1-9.6-1.715V9.6a2.4 2.4 0 0 1 2.4-2.4h2.4zM9.6 6a1.2 1.2 0 0 1 1.2-1.2h2.4A1.2 1.2 0 0 1 14.4 6v1.2H9.6V6zm1.2 6a1.2 1.2 0 0 1 1.2-1.2h.012a1.2 1.2 0 0 1 0 2.4H12a1.2 1.2 0 0 1-1.2-1.2z"
        fill={color}
      />
      <path
        d="M2.4 16.43v2.77a2.4 2.4 0 0 0 2.4 2.4h14.4a2.4 2.4 0 0 0 2.4-2.4v-2.77A29.967 29.967 0 0 1 12 18c-3.355 0-6.584-.551-9.6-1.57z"
        fill={color}
      />
    </svg>
  )
}

export default BriefCaseIcon
