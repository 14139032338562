import { useEffect, useState } from 'react'
import { FC } from 'react'
import TooltipVerified from './TooltipVerifyCompany/TooltipVerifyCompany'

export const LOGO_DEFAULT = '/img/logo_default.png'

// export const borderStyle = {
//   borderWidth: 1,
//   borderStyle: 'solid',
//   borderColor: '#e8e8e8'
// }

const LogoCompanyView: FC<{
  src?: string
  alt?: string
  title?: string
  className?: string
  isAmp?: boolean
  sizeWidthAmp?: number
  sizeHeightAmp?: number
  size?: number | '100%'
  radius?: number
  isVerified?: boolean
  mobileSize?: number
}> = ({
  mobileSize,
  src,
  alt,
  title,
  className,
  // isAmp,
  // sizeWidthAmp,
  // sizeHeightAmp,
  size,
  radius = 5,
  isVerified = false
}) => {
  const [srcState, setSrc] = useState(src || LOGO_DEFAULT)
  const [error, setError] = useState(false)
  const borderRadius = radius // radius ? radius : size > 100 ? 5 : 0
  const onError = () => {
    if (!error) {
      setError(true)
      setSrc(LOGO_DEFAULT)
    }
  }

  useEffect(() => {
    if (src) {
      setSrc(src)
    } else {
      setSrc(LOGO_DEFAULT)
    }
  }, [src])

  // if (isAmp) {
  //   return (
  //     <div style={{ position: 'relative' }}>
  //       {isVerified && <TooltipVerified />}
  //       <div
  //         className="d-flex align-items-center"
  //         style={{
  //           width: sizeWidthAmp,
  //           height: sizeHeightAmp || sizeWidthAmp,
  //           margin: 'auto',
  //           overflow: 'hidden',
  //           borderRadius,
  //           ...borderStyle
  //         }}>
  //         <amp-img
  //           width={sizeWidthAmp}
  //           height={sizeHeightAmp || sizeWidthAmp}
  //           src={srcState}
  //           alt={alt}
  //           title={title}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <>
      <style jsx>{`
        .desktop-logo-company {
          display: flex;
          align-items: center;
        }
        .mobile-logo-company {
          display: flex;
          align-items: center;
        }
        @media only screen and (min-width: 768px) {
          .mobile-logo-company {
            display: none;
          }
        }
        @media only screen and (max-width: 768px) {
          .desktop-logo-company {
            display: none;
          }
        }
      `}</style>
      <div style={{ position: 'relative' }}>
        {isVerified && <TooltipVerified />}
        <div
          style={{
            width: size,
            height: size,
            margin: 'auto',
            overflow: 'hidden',
            borderRadius
          }}
          className="desktop-logo-company logo-company">
          <img
            alt={alt}
            title={title}
            src={srcState}
            onError={onError}
            style={{ maxWidth: '100%' }}
            className={className}
          />
        </div>
        <div
          style={{
            width: mobileSize || size,
            height: mobileSize || size,
            margin: 'auto',
            overflow: 'hidden',
            borderRadius
          }}
          className="mobile-logo-company logo-company d-flex align-items-center">
          <img
            alt={alt}
            title={title}
            src={srcState}
            onError={onError}
            style={{ maxWidth: '100%' }}
            className={className}
          />
        </div>
      </div>
    </>
  )
}
export default LogoCompanyView
