import { FC } from 'react'
const MailIcon: FC<{ color?: string; size?: number }> = ({
  color = '#fff',
  size = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 21) / 20}
      viewBox="0 0 20 21"
      fill="none">
      <path
        d="M2.003 6.734 10 10.732l7.997-3.998A2 2 0 0 0 16 4.85H4a2 2 0 0 0-1.997 1.884z"
        fill={color}
      />
      <path
        d="m18 8.968-8 4-8-4v5.882a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.968z"
        fill={color}
      />
    </svg>
  )
}

export default MailIcon
