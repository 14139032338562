import { ReactNode } from 'react'
import ErrorIcon from '../../Icons/Error'
import { Typography } from '../../Typography'
import {
  getClassNameInput,
  getClassNameLeftIcon,
  getClassNameLeftGroup,
  getClassNameRightIcon,
  getClassNameRightGroup
} from './index.utils'

interface InputProps {
  size?: 'small' | 'default' | 'large' | 'large-xl'
  type?: 'text' | 'number'
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  onChange?: (value: string | number) => void
  defaultValue?: string | number
  placeholder?: string
  value?: string | number
  status?: '' | 'error'
  statusText?: string
  leftInput?: ReactNode
  leftGroup?: ReactNode
  rightInput?: ReactNode
  rightGroup?: ReactNode
  disabled?: boolean
  className?: string
  name?: string
}

export const Input = ({
  size = 'default',
  type = 'text',
  onClick = undefined,
  onChange = undefined,
  defaultValue = undefined,
  placeholder = '',
  value = undefined,
  status = '',
  statusText = undefined,
  leftInput = undefined,
  leftGroup = undefined,
  rightInput = undefined,
  rightGroup = undefined,
  disabled = false,
  className = '',
  name = ''
}: InputProps) => {
  const classNameLeftIcon = getClassNameLeftIcon(size, !!leftGroup)
  const classNameLeftGroup = getClassNameLeftGroup()
  const classNameRightIcon = getClassNameRightIcon(size, !!rightGroup)
  const classNameRightGroup = getClassNameRightGroup()
  const classNameInput = getClassNameInput({
    size,
    leftInput: !!leftInput,
    leftGroup: !!leftGroup,
    rightInput: !!rightInput,
    rightGroup: !!rightGroup,
    disabled,
    status
  })

  return (
    <>
      <div
        className="relative"
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}>
        {leftGroup ? (
          <div className={classNameLeftGroup}>{leftGroup}</div>
        ) : null}
        {leftInput ? (
          <div className={classNameLeftIcon}>{leftInput}</div>
        ) : null}
        <input
          name={name}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
          className={`${className} ${classNameInput}`}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
        {status === 'error' ? (
          <div className={classNameRightIcon}>
            <ErrorIcon />
          </div>
        ) : null}
        {rightInput && status === '' ? (
          <div className={classNameRightIcon}>{rightInput}</div>
        ) : null}
        {rightGroup ? (
          <div className={classNameRightGroup}>{rightGroup}</div>
        ) : null}
      </div>

      {statusText ? (
        <Typography size={14} typeClass="text-red-600 mt-2 mb-0 leading-[22px]">
          {statusText}
        </Typography>
      ) : null}
    </>
  )
}
