export const getTypography = (
  size: number,
  type: string,
  typeClass?: string
) => {
  let className = ''

  switch (size) {
    case 10:
      className += 'text-[10px]'
      break
    case 12:
      className += 'text-[12px]'
      break
    case 14:
      className += 'text-[14px]'
      break
    case 16:
      className += 'text-[16px]'
      break
    case 18:
      className += 'text-[18px]'
      break
    case 20:
      className += 'text-[20px]'
      break
    case 24:
      className += 'text-[24px]'
      break
    case 28:
      className += 'text-[28px]'
      break
    case 30:
      className += 'text-[30px]'
      break

    default:
      className += 'text-[16px]'
      break
  }

  if (typeClass) {
    className += ` ${typeClass}`
  } else {
    switch (type) {
      case 'medium':
        className += ' text-gray-800 font-medium'
        break
      case 'bold':
        className += ' text-gray-1000 font-bold'
        break
      case 'extrabold':
        className += ' text-gray-1000 font-extrabold'
        break
      case 'regular':
        className += ' text-gray-600'
        break

      default:
        className += ''
        break
    }
  }

  return className
}
