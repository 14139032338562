import { ReactNode } from 'react'
import { getTypography } from './index.utils'

interface TypographyProps {
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | 30
  type?: 'regular' | 'medium' | 'bold' | 'extrabold'
  tagHTML?: 'p' | 'b' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: ReactNode
  typeClass?: string
}

export const Typography = ({
  size = 16,
  type = 'regular',
  tagHTML = 'p',
  ...props
}: TypographyProps) => {
  const className = getTypography(size, type, props.typeClass)

  if (tagHTML === 'b') return <b className={className}>{props.children}</b>
  if (tagHTML === 'span')
    return <span className={className}>{props.children}</span>
  if (tagHTML === 'h1') return <h1 className={className}>{props.children}</h1>
  if (tagHTML === 'h2') return <h2 className={className}>{props.children}</h2>
  if (tagHTML === 'h3') return <h3 className={className}>{props.children}</h3>
  if (tagHTML === 'h4') return <h4 className={className}>{props.children}</h4>
  if (tagHTML === 'h5') return <h5 className={className}>{props.children}</h5>
  if (tagHTML === 'h6') return <h6 className={className}>{props.children}</h6>

  return <p className={className}>{props.children}</p>
}
