import { FC } from 'react'
const StartIcon: FC<{ color?: string; size?: number }> = ({
  color = '#fff',
  size = 15
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 14) / 15}
      viewBox="0 0 15 14"
      fill="none">
      <path
        d="m12.916 4.827-3.471-.504-1.552-3.146a.44.44 0 0 0-.785 0L5.557 4.323l-3.472.504a.437.437 0 0 0-.242.747l2.512 2.449-.594 3.457a.437.437 0 0 0 .635.46L7.5 10.31l3.105 1.632a.437.437 0 0 0 .634-.46l-.593-3.458 2.511-2.45a.437.437 0 0 0-.242-.746z"
        fill={color}
      />
    </svg>
  )
}

export default StartIcon
