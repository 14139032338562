import Image from 'next/image'
import { FC, useCallback, useEffect, useState } from 'react'
import FreeCLogo from '../../../public/img/logo-freec.png'
import { Button } from '../Button'
import { Link as LinkVariable } from '../../link'
import ENV from '../../env'
import Link from 'next/link'

const Header: FC<{ classCustom?: string; scrollDownClass?: string }> = ({
  classCustom,
  scrollDownClass
}) => {
  const [lastYPos, setLastYPos] = useState(0)
  const [isScrollDown, setIsScrollDown] = useState(false)
  const handleOnScroll = useCallback(() => {
    const currWindowPos =
      window.pageYOffset || document.documentElement.scrollTop

    if (currWindowPos >= lastYPos && currWindowPos > 160) {
      //scroll down
      !isScrollDown && setIsScrollDown(true)
    } else if (currWindowPos <= 160) {
      //scroll up
      isScrollDown && setIsScrollDown(false)
    }
    currWindowPos !== lastYPos && setLastYPos(currWindowPos)
  }, [lastYPos, isScrollDown])

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleOnScroll)
    }
  }, [handleOnScroll])
  return (
    <div
      className={`flex justify-between px-4 sm:px-8 py-[13px] sm:py-4 w-full z-10 ${
        isScrollDown ? `bg-white ${scrollDownClass || ''}` : ''
      } ${classCustom ? classCustom : ''}`}>
      <div className="flex items-start">
        <Link href={LinkVariable.JOB_LIST}>
          <a className="mr-3">
            <Image width={47} height={32} src={FreeCLogo} alt="FreeC logo" />
          </a>
        </Link>

        <span className="text-[10px] leading-4 text-bold text-white px-1 inline-block bg-[#34d399] rounded-[2px]">
          REMOTE
        </span>
      </div>
      <div className="flex items-center">
        <Button
          onClick={() =>
            LinkVariable.EMPLOYER_POST_JOB &&
            window.open(LinkVariable.EMPLOYER_POST_JOB, '_blank')
          }
          className="hidden sm:inline-block mr-8">
          Post a job - Free
        </Button>
        <Button
          onClick={() =>
            LinkVariable.EMPLOYER_POST_JOB &&
            window.open(LinkVariable.EMPLOYER_POST_JOB, '_blank')
          }
          className="sm:hidden mr-4">
          Post a job
        </Button>
        <a
          href={`${ENV.PUBLIC_CANDIDATE_URL}/login`}
          target="_blank"
          className="hidden text-sm sm:inline-block text-[#374151] text-medium py-[5px] px-[10px] mr-6 sm:mr-2"
          rel="noreferrer">
          Sign in
        </a>
        <span className="hidden text-sm sm:inline-block text-[#374151] text-medium">
          /
        </span>
        <a
          href={`${ENV.PUBLIC_CANDIDATE_URL}/register`}
          target="_blank"
          className="text-[#1890ff] text-sm text-medium sm:py-[5px] sm:px-[10px]  sm:ml-6 sm:ml-2"
          rel="noreferrer">
          Sign up
        </a>
      </div>
    </div>
  )
}

export default Header
