import ENV from './env'
function createIdSlug(slug?: string, id?: number | string): string {
  return `${slug}-i${id}`
}
function createNewCompanyIdSlug(slug: string, id: number | string): string {
  return `${slug}-fi${id}`
}

export const Link = {
  LOGIN: `/login`,
  JOB_LIST: `/`,
  // EMPLOYER_POST_JOB: 'https://employer.freec.asia/',
  EMPLOYER_POST_JOB: ENV.PUBLIC_EMPLOYER_URL,
  CANDIDATE_APPLY_JOB: (slug: string, id: number | string) =>
    `${ENV.PUBLIC_CANDIDATE_URL}/jobs/[slug]`.replace(
      '[slug]',
      createIdSlug(slug, id)
    ),
  LINK_URL_JOB_DETAILS: (slug?: string, id?: number | string) =>
    '/[slug]'.replace('[slug]', createIdSlug(slug, id)),
  CANDIDATE_COMPANY_PUBLISH_JOBS: (company_slug: string, company_id: number) =>
    `${ENV.PUBLIC_CANDIDATE_URL}/company/[slug]`.replace(
      '[slug]',
      createNewCompanyIdSlug(company_slug, company_id)
    )
}
